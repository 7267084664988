import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

function FooterHome({ className }) {
   return (
      <section className={`appie-footer-area ${className || ""}`}>
         <div className="container">
            {/*<div className="row">*/}
            {/*   <div className="col-lg-4 col-md-6">*/}
            {/*      <div className="footer-about-widget">*/}
            {/*         <div className="logo">*/}
            {/*            <a href="#">*/}
            {/*               <img src={logo} alt="" />*/}
            {/*            </a>*/}
            {/*         </div>*/}
            {/*         <p>Appie WordPress is theme is the last theme you will ever have.</p>*/}
            {/*         <a href="#">*/}
            {/*            Read More <i className="fal fa-arrow-right" />*/}
            {/*         </a>*/}
            {/*         <div className="social mt-30">*/}
            {/*            <ul>*/}
            {/*               <li>*/}
            {/*                  <a href="#">*/}
            {/*                     <i className="fab fa-facebook-f" />*/}
            {/*                  </a>*/}
            {/*               </li>*/}
            {/*               <li>*/}
            {/*                  <a href="#">*/}
            {/*                     <i className="fab fa-twitter" />*/}
            {/*                  </a>*/}
            {/*               </li>*/}
            {/*               <li>*/}
            {/*                  <a href="#">*/}
            {/*                     <i className="fab fa-pinterest-p" />*/}
            {/*                  </a>*/}
            {/*               </li>*/}
            {/*               <li>*/}
            {/*                  <a href="#">*/}
            {/*                     <i className="fab fa-linkedin-in" />*/}
            {/*                  </a>*/}
            {/*               </li>*/}
            {/*            </ul>*/}
            {/*         </div>*/}
            {/*      </div>*/}
            {/*   </div>*/}
            {/*   <div className="col-lg-2 col-md-6">*/}
            {/*      <div className="footer-navigation">*/}
            {/*         <h4 className="title">Company</h4>*/}
            {/*         <ul>*/}
            {/*            <li>*/}
            {/*               <Link to="/about-us">About Us</Link>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*               <Link to="/Service">Service</Link>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*               <a href="#">Case Studies</a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*               <Link to="/news">Blog</Link>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*               <Link to="/contact">Contact</Link>*/}
            {/*            </li>*/}
            {/*         </ul>*/}
            {/*      </div>*/}
            {/*   </div>*/}
            {/*   <div className="col-lg-3 col-md-6">*/}
            {/*      <div className="footer-navigation">*/}
            {/*         <h4 className="title">Support</h4>*/}
            {/*         <ul>*/}
            {/*            <li>*/}
            {/*               <Link to="/about-us">Community</Link>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*               <a href="#">Resources</a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*               <a href="#">Faqs</a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*               <a href="#">Privacy Policy</a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*               <a href="#">Careers</a>*/}
            {/*            </li>*/}
            {/*         </ul>*/}
            {/*      </div>*/}
            {/*   </div>*/}
            {/*   <div className="col-lg-3 col-md-6">*/}
            {/*      <div className="footer-widget-info">*/}
            {/*         <h4 className="title">Get In Touch</h4>*/}
            {/*         <ul>*/}
            {/*            <li>*/}
            {/*               <a href="#">*/}
            {/*                  <i className="fal fa-envelope" /> support@appie.com*/}
            {/*               </a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*               <a href="#">*/}
            {/*                  <i className="fal fa-phone" /> +(642) 342 762 44*/}
            {/*               </a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*               <a href="#">*/}
            {/*                  <i className="fal fa-map-marker-alt" /> 442 Belle Terre St Floor 7,*/}
            {/*                  San Francisco, AV 4206*/}
            {/*               </a>*/}
            {/*            </li>*/}
            {/*         </ul>*/}
            {/*      </div>*/}
            {/*   </div>*/}
            {/*</div>*/}
            <div className="row">
               <div className="col-lg-4 col-md-12">
                  <div className="footer-about-widget">
                     <div className="logo">
                        <a href="#">
                           <img src={logo} alt="" width="60px" />
                        </a>
                     </div>
                  </div>
               </div>
               <div className="col-lg-4 col-md-12">
                  <div className="footer-navigation">
                     <h4 className="title">Pages</h4>
                     <ul>
                        <li>
                           <Link to="/">Home</Link>
                        </li>
                        <li>
                           <Link to="/pricing">Pricing</Link>
                        </li>
                        <li>
                           <Link to="/contact">Contact Us</Link>
                        </li>
                     </ul>
                  </div>
               </div>
               {/*<div className="col-lg-3 col-md-12">*/}
               {/*   <div className="footer-navigation">*/}
               {/*      <h4 className="title">Legal</h4>*/}
               {/*      <ul>*/}
               {/*         <li>*/}
               {/*            <Link to="/terms-of-use">Terms of Use</Link>*/}
               {/*         </li>*/}
               {/*         <li>*/}
               {/*            <Link to="/privacy">Privacy Policy</Link>*/}
               {/*         </li>*/}
               {/*      </ul>*/}
               {/*   </div>*/}
               {/*</div>*/}
               <div className="col-lg-4 col-md-12">
                  <div className="footer-widget-info">
                     <h4 className="title">Get In Touch</h4>
                     <ul>
                        <li>
                           {/*<a href="#">*/}
                           <p>
                              <i className="fal fa-envelope" />
                              <span className="rev hemol">ymer</span>
                           </p>
                           {/*</a>*/}
                        </li>
                        <li>
                           {/*<a href="#">*/}
                           <p>
                              <i className="fal fa-phone" />
                              <span className="rev phan">(574)</span>
                           </p>
                           {/*</a>*/}
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-12 col-md-12">
                  <br />
                  <br />
                  <div className="copyright-text">
                     <p>
                        <span className="line">Copyright © 2023 Remy Money, Inc.&nbsp;</span>
                        <span className="line">All rights reserved.</span>
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}

export default FooterHome;
