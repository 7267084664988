import flagsmith from "flagsmith";
import { useFlags, useFlagsmith } from "flagsmith/react";
import React from "react";
import homeHero from "../../assets/images/home-hero2.jpg";
// import heroThumbOne from "../../assets/images/hero-thumb-1.png";
// import heroThumbTwo from "../../assets/images/hero-thumb-2.png";
import shapeTwo from "../../assets/images/shape/shape-2.png";
import shapeThree from "../../assets/images/shape/shape-3.png";
import shapeFour from "../../assets/images/shape/shape-4.png";
import { Link } from "react-router-dom";
// import VisitorData from "./who";

function HeroHome({ className }) {
   const flags = useFlags([
      "home_hero_intro_text",
      "home_hero_title_text",
      "home_hero_content_text",
      "home_hero_button1_text",
      "home_hero_button2_text",
      "home_hero_button1_link",
      "home_hero_button2_link",
      "home_hero_floating_shapes",
   ]);
   return (
      <>
         <section className={`appie-hero-area ${className || ""}`}>
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-6">
                     <div className="appie-hero-content">
                        {flags?.home_hero_intro_text.enabled && (
                           <span>{flags.home_hero_intro_text.value}</span>
                        )}
                        <h1 className="appie-title">{flags?.home_hero_title_text.value}</h1>
                        {/*<VisitorData />*/}
                        <p>{flags?.home_hero_content_text.value}</p>
                        <ul>
                           {flags?.home_hero_button1_text.enabled && (
                              <li>
                                 <Link to="/pricing">
                                    {/*<i className="fab fa-apple" />{" "}*/}
                                    {flags.home_hero_button1_text.value}
                                 </Link>
                              </li>
                           )}
                           {flags?.home_hero_button2_text.enabled && (
                              <li>
                                 <a className="item-2" href="#features">
                                    {/*<i className="fab fa-google-play" />{" "}*/}
                                    {flags.home_hero_button2_text.value}
                                 </a>
                              </li>
                           )}
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-6">
                     <div className="appie-hero-thumb">
                        <div
                           className="thumb wow animated fadeInUp"
                           data-wow-duration="2000ms"
                           data-wow-delay="200ms"
                        >
                           <img src={homeHero} alt="" height="600px" />
                        </div>
                        {/*<div*/}
                        {/*   className="thumb-2 wow animated fadeInRight"*/}
                        {/*   data-wow-duration="2000ms"*/}
                        {/*   data-wow-delay="600ms"*/}
                        {/*>*/}
                        {/*   <img src={heroThumbTwo} alt="" />*/}
                        {/*</div>*/}
                     </div>
                  </div>
               </div>
            </div>
            {flags && flags.home_hero_floating_shapes.enabled && (
               <>
                  <div className="hero-shape-1">
                     <img src={shapeTwo} alt="" />
                  </div>
                  <div className="hero-shape-2">
                     <img src={shapeThree} alt="" />
                  </div>
                  <div className="hero-shape-3">
                     <img src={shapeFour} alt="" />
                  </div>
               </>
            )}
         </section>
      </>
   );
}

export default HeroHome;
