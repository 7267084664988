import React from "react";
import flagsmith from "flagsmith";
import { useFlags, useFlagsmith } from "flagsmith/react";
import IconOne from "../../assets/images/icon/1.png";
import IconTwo from "../../assets/images/icon/2.png";
import IconThree from "../../assets/images/icon/3.png";
import SecurityIcon from "../../assets/images/icon/padlock.png";
import ComplianceIcon from "../../assets/images/icon/compliant.png";
import PrivacyIcon from "../../assets/images/icon/fingerprint-scan.png";
import IconFour from "../../assets/images/icon/4.png";

function ServicesHome({ className }) {
   const flags = useFlags([
      "home_service_title_text",
      "home_service_content_text",
      "home_service_topleft_title_text",
      "home_service_topleft_content_text",
      "home_service_topright_title_text",
      "home_service_topright_content_text",
      "home_service_bottomleft_title_text",
      "home_service_bottomleft_content_text",
      "home_service_bottomright_title_text",
      "home_service_bottomright_content_text",
   ]);
   return (
      <section className={`appie-service-area pt-140 pb-100 ${className}`} id="service">
         <div className="container">
            <div className="row justify-content-center">
               <div className="col-lg-6">
                  <div className="appie-section-title text-center">
                     <h3 className="appie-title">{flags && flags.home_service_title_text.value}</h3>
                     <p>{flags && flags.home_service_content_text.value}</p>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-4 col-md-6">
                  <div
                     className="appie-single-service text-center mt-30 wow animated fadeInUp"
                     data-wow-duration="2000ms"
                     data-wow-delay="200ms"
                  >
                     <div className="icon">
                        <img src={SecurityIcon} alt="" />
                        {/*<span>1</span>*/}
                     </div>
                     <h4 className="appie-title">
                        {flags && flags.home_service_topleft_title_text.value}
                     </h4>
                     <p>{flags && flags.home_service_topleft_content_text.value}</p>
                  </div>
               </div>
               <div className="col-lg-4 col-md-6">
                  <div
                     className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                     data-wow-duration="2000ms"
                     data-wow-delay="400ms"
                  >
                     <div className="icon">
                        <img src={ComplianceIcon} alt="" />
                        {/*<span>2</span>*/}
                     </div>
                     <h4 className="appie-title">
                        {flags && flags.home_service_topright_title_text.value}
                     </h4>
                     <p>{flags && flags.home_service_topright_content_text.value}</p>
                  </div>
               </div>
               <div className="col-lg-4 col-md-6">
                  <div
                     className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                     data-wow-duration="2000ms"
                     data-wow-delay="600ms"
                  >
                     <div className="icon">
                        <img src={PrivacyIcon} alt="" />
                        {/*<span>3</span>*/}
                     </div>
                     <h4 className="appie-title">
                        {flags && flags.home_service_bottomleft_title_text.value}
                     </h4>
                     <p>{flags && flags.home_service_bottomleft_content_text.value}</p>
                  </div>
               </div>
               {/*<div className="col-lg-3 col-md-6">*/}
               {/*   <div*/}
               {/*      className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"*/}
               {/*      data-wow-duration="2000ms"*/}
               {/*      data-wow-delay="800ms"*/}
               {/*   >*/}
               {/*      <div className="icon">*/}
               {/*         <img src={IconFour} alt="" />*/}
               {/*         <span>4</span>*/}
               {/*      </div>*/}
               {/*      <h4 className="appie-title">*/}
               {/*         {flags && flags.home_service_bottomright_title_text.value}*/}
               {/*      </h4>*/}
               {/*      <p>{flags && flags.home_service_bottomright_content_text.value}</p>*/}
               {/*   </div>*/}
               {/*</div>*/}
            </div>
         </div>
      </section>
   );
}

export default ServicesHome;
