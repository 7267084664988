import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import AboutUs from "./components/AboutUs";
import AboutUsTwo from "./components/AboutUs/AboutUsTwo";
import Contact from "./components/Contact";
import Error from "./components/Error";
import Loader from "./components/Helper/Loader";
import ScrollToTop from "./components/Helper/ScrollToTop";
import Home from "./components/Home";
import HomeDark from "./components/HomeDark";
import HomeEight from "./components/HomeEight";
import HomeFive from "./components/HomeFive";
import HomeFour from "./components/HomeFour";
import HomeOne from "./components/HomeOne";
import HomeRtl from "./components/HomeRtl";
import HomeSeven from "./components/HomeSeven";
import HomeSix from "./components/HomeSix";
import HomeThree from "./components/HomeThree";
import Hometwo from "./components/HomeTwo";
import News from "./components/News";
import SingleNews from "./components/News/SingleNews";
import Service from "./components/Service";
import Shops from "./components/Shops";
import ShopDetails from "./components/Shops/Details";
import Privacy from "./components/Privacy";
import Pricing from "./components/Pricing";
import Welcome from "./components/Welcome";
import Join from "./components/Join";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

function Routes() {
   // Loading animation trigger, set to true to enable loading animation features
   const [loading, setLoading] = useState(false);

   let _ = useVisitorData({ extendedResult: true }, { immediate: true });

   const history = useHistory();

   useEffect(() => {
      window.scrollTo(0, 0);

      // // Listen for changes to the current location, and update the title
      // let unlisten = history.listen(({ action, location }) => {
      //    console.log(location);
      // });
      //
      // // Clean up the listener when the component is unmounted.
      // return unlisten;
   });

   // Loading animation trigger
   useEffect(() => {
      setTimeout(() => {
         setLoading(false);
      }, 2000);
   });

   return (
      <>
         {/*Loading Animation*/}
         {loading && (
            <div className={`appie-loader ${loading ? "active" : ""}`}>
               <Loader />
            </div>
         )}
         <div className={`appie-visible ${loading === false ? "active" : ""}`}>
            <Router>
               <ScrollToTop>
                  <Switch>
                     <Route exact path="/" component={Home} />
                     {/*<Route exact path="/home-one" component={HomeOne} />*/}
                     {/*<Route exact path="/home-two" component={Hometwo} />*/}
                     {/*<Route exact path="/home-three" component={HomeThree} />*/}
                     {/*<Route exact path="/home-four" component={HomeFour} />*/}
                     {/*<Route exact path="/home-five" component={HomeFive} />*/}
                     {/*<Route exact path="/home-six" component={HomeSix} />*/}
                     {/*<Route exact path="/home-seven" component={HomeSeven} />*/}
                     {/*<Route exact path="/home-eight" component={HomeEight} />*/}
                     {/*<Route exact path="/home-dark" component={HomeDark} />*/}
                     {/*<Route exact path="/home-rtl" component={HomeRtl} />*/}
                     {/*<Route exact path="/news" component={News} />*/}
                     {/*<Route exact path="/news/single-news" component={SingleNews} />*/}
                     <Route exact path="/pricing" component={Pricing} />
                     <Route exact path="/privacy" component={Privacy} />
                     {/*<Route exact path="/service" component={Service} />*/}
                     {/*<Route exact path="/about-us" component={AboutUs} />*/}
                     <Route exact path="/contact" component={Contact} />
                     <Route exact path="/error" component={Error} />
                     {/*<Route exact path="/about-us-another" component={AboutUsTwo} />*/}
                     {/*<Route exact path="/shops" component={Shops} />*/}
                     {/*<Route exact path="/shops/shop-details" component={ShopDetails} />*/}
                     <Route exact path="/join" component={Join} />
                     <Route exact path="/welcome" component={Welcome} />
                     <Route component={Error} />
                  </Switch>
               </ScrollToTop>
            </Router>
         </div>
      </>
   );
}

export default Routes;
