import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

function FooterWelcome() {
   return (
      <section className="appie-footer-area appie-footer-about-area appie-footer-8-area">
         <div className="container">
            <div className="row">
               <div className="col-lg-3 col-md-12">
                  <div className="footer-about-widget">
                     <div className="logo">
                        <a href="#">
                           <img src={logo} alt="" width="60px" />
                        </a>
                     </div>
                  </div>
               </div>
               <div className="col-lg-3 col-md-12">
                  <div className="footer-navigation">
                     <h4 className="title">Pages</h4>
                     <ul>
                        <li>
                           <Link to="/">Home</Link>
                        </li>
                        <li>
                           <Link to="/pricing">Pricing</Link>
                        </li>
                        <li>
                           <Link to="/contact">Contact Us</Link>
                        </li>
                     </ul>
                  </div>
               </div>
               <div className="col-lg-3 col-md-12">
                  <div className="footer-navigation">
                     <h4 className="title">Legal</h4>
                     <ul>
                        <li>
                           <Link to="/terms-of-use">Terms of Use</Link>
                        </li>
                        <li>
                           <Link to="/privacy">Privacy Policy</Link>
                        </li>
                     </ul>
                  </div>
               </div>
               <div className="col-lg-3 col-md-12">
                  <div className="footer-widget-info">
                     <h4 className="title">Get In Touch</h4>
                     <ul>
                        <li>
                           {/*<a href="#">*/}
                           <p>
                              <i className="fal fa-envelope" />
                              <span className="rev hemol">ymer</span>
                           </p>
                           {/*</a>*/}
                        </li>
                        <li>
                           {/*<a href="#">*/}
                           <p>
                              <i className="fal fa-phone" />
                              <span className="rev phan">(574)</span>
                           </p>
                           {/*</a>*/}
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-12 col-md-12">
                  <br />
                  <br />
                  <div className="copyright-text">
                     <p>
                        <span className="line">Copyright © 2023 Remy Money, Inc.&nbsp;</span>
                        <span className="line">All rights reserved.</span>
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}

export default FooterWelcome;
