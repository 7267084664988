import React from "react";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import Drawer from "../Mobile/Drawer";
import BlogSideBar from "../News/BlogSideBar";
import HeaderPrivacy from "../Privacy/HeaderPrivacy";
import HeroNews from "../News/HeroNews";
import ContentPrivacy from "./ContentPrivacy";
import FooterHome from "../Home/FooterHome";

function Privacy() {
   const [drawer, drawerAction] = useToggle(false);
   return (
      <>
         <Drawer drawer={drawer} action={drawerAction.toggle} />
         <HeaderPrivacy action={drawerAction.toggle} />
         <HeroNews
            title="Privacy Policy"
            breadcrumb={[
               { link: "/", title: "Home" },
               { link: "/privacy", title: "Privacy Policy" },
            ]}
         />
         <section className="blogpage-section">
            <div className="container">
               <div className="row">
                  <div className="col-lg-8 col-md-7">
                     <ContentPrivacy />
                  </div>
                  {/*<div className="col-lg-4 col-md-5">*/}
                  {/*   <BlogSideBar />*/}
                  {/*</div>*/}
               </div>
            </div>
         </section>
         <FooterHome />
         <BackToTop />
      </>
   );
}

export default Privacy;
