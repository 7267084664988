import React from "react";
import workThumb from "../../assets/images/how-it-work-thumb.png";

function NowWhatWelcome() {
   return (
      <>
         <div className="appie-how-it-work-area pt-90 pb-100">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-6">
                     <div className="how-it-work-thumb text-center">
                        <img src={workThumb} alt="" />
                     </div>
                  </div>
                  <div className="col-lg-6">
                     <div className="appie-how-it-work-content">
                        <h2 className="title">Now what?</h2>
                        <p>
                           We'll reach out to walk you through a few steps so Remy can get you back
                           to talking to clients.
                        </p>
                        <div className="row">
                           <div className="col-sm-5">
                              <div className="how-it-work-box">
                                 <span>1</span>
                                 <h5 className="title">Schedule a call with us above</h5>
                              </div>
                           </div>
                           <div className="col-sm-1"></div>
                           <div className="col-sm-5">
                              <div className="how-it-work-box">
                                 <span>2</span>
                                 <h5 className="title">Try Remy for yourself</h5>
                              </div>
                           </div>
                           <div className="col-sm-1"></div>
                           <div className="col-sm-5">
                              <div className="how-it-work-box">
                                 <span>3</span>
                                 <h5 className="title">Add your existing tools</h5>
                              </div>
                           </div>
                           <div className="col-sm-1"></div>
                           <div className="col-sm-5">
                              <div className="how-it-work-box">
                                 <span>4</span>
                                 <h5 className="title">Send Remy your first task</h5>
                              </div>
                           </div>
                           <div className="col-sm-1"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default NowWhatWelcome;
