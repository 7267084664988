import React from "react";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import Drawer from "../Mobile/Drawer";
import BlogHomeOne from "./BlogHomeOne";
import FAQHome from "./FAQHome";
import FeaturesHome from "./FeaturesHome";
import FooterHome from "./FooterHome";
import HeroHome from "./HeroHome";
import HomeHeader from "./HomeOneHeader";
import PricingHomeOne from "./PricingHomeOne";
import ProjectHomeOne from "./ProjectHomeOne";
import ServicesHome from "./ServicesHome";
import TeamHomeOne from "./TeamHomeOne";
import TestimonialHomeOne from "./TestimonialHomeOne";
import TrafficHome from "./TrafficHome";
import HighlightsHome from "./HighlightsHome";

function Home() {
   const [drawer, drawerAction] = useToggle(false);

   return (
      <>
         <Drawer drawer={drawer} action={drawerAction.toggle} />
         <HomeHeader action={drawerAction.toggle} />
         <HeroHome />
         <HighlightsHome />
         <TrafficHome />
         <ServicesHome />
         {/*<FeaturesHome />*/}
         {/*<TestimonialHomeOne />*/}
         {/*<TeamHomeOne />*/}
         {/*<PricingHomeOne />*/}
         {/*<FAQHome />*/}
         {/*<BlogHomeOne />*/}
         {/*<ProjectHomeOne />*/}
         <FooterHome />
         <BackToTop />
      </>
   );
}

export default Home;
