import flagsmith from "flagsmith";
import { useFlags, useFlagsmith } from "flagsmith/react";
import React, { useState } from "react";
import { PopupButton, PopupWidget } from "react-calendly";

const BUY_BUTTON_LINKS = {
   professional_monthly: "https://buy.stripe.com/eVaaF6ebW07t6f6eUV",
   professional_annual: "https://buy.stripe.com/8wM28Afg08DZbzqbIK",
};

const WAITLIST_BUTTON_LINKS = {
   starter: "https://getwaitlist.com/waitlist/12398",
};

const CALENDLY_LINKS = {
   enterprise: "https://calendly.com/jay-jacobs/enterprise",
};

function Plans({ className }) {
   const [toggleSwitch, setSwitchValue] = useState(false);

   const flags = useFlags([
      "pricing_plans_title_text",
      "pricing_plans_content_text",
      "pricing_plans_popular_text",
      "pricing_plans_left_title_text",
      "pricing_plans_left_monthlyprice_text",
      "pricing_plans_left_annualprice_text",
      "pricing_plans_left_intro_text",
      "pricing_plans_left_detailintro_text",
      "pricing_plans_left_detail1_text",
      "pricing_plans_left_detail2_text",
      "pricing_plans_left_detail3_text",
      "pricing_plans_left_detail4_text",
      "pricing_plans_left_detail5_text",
      "pricing_plans_left_detail6_text",
      "pricing_plans_left_button_text",
      "pricing_plans_middle_title_text",
      "pricing_plans_middle_monthlyprice_text",
      "pricing_plans_middle_annualprice_text",
      "pricing_plans_middle_intro_text",
      "pricing_plans_middle_detailintro_text",
      "pricing_plans_middle_detail1_text",
      "pricing_plans_middle_detail2_text",
      "pricing_plans_middle_detail3_text",
      "pricing_plans_middle_detail4_text",
      "pricing_plans_middle_detail5_text",
      "pricing_plans_middle_detail6_text",
      "pricing_plans_middle_button_text",
      "pricing_plans_right_title_text",
      "pricing_plans_right_monthlyprice_text",
      "pricing_plans_right_annualprice_text",
      "pricing_plans_right_intro_text",
      "pricing_plans_right_detailintro_text",
      "pricing_plans_right_detail1_text",
      "pricing_plans_right_detail2_text",
      "pricing_plans_right_detail3_text",
      "pricing_plans_right_detail4_text",
      "pricing_plans_right_detail5_text",
      "pricing_plans_right_detail6_text",
      "pricing_plans_right_button_text",
   ]);

   return (
      <>
         <section className={`appie-pricing-area pt-190 pb-90 ${className || ""}`}>
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="appie-section-title text-center">
                        <h3 className="appie-title">{flags?.pricing_plans_title_text.value}</h3>
                        {flags?.pricing_plans_content_text.enabled && (
                           <p>{flags?.pricing_plans_content_text.value}</p>
                        )}
                     </div>
                     <ul
                        className="list-inline text-center switch-toggler-list"
                        role="tablist"
                        id="switch-toggle-tab"
                     >
                        <li className={`month ${toggleSwitch ? "active" : ""}`}>
                           <a href="#">Monthly</a>
                        </li>
                        <li>
                           <label
                              onClick={() => setSwitchValue(!toggleSwitch)}
                              className={`switch ${toggleSwitch ? "on" : "off"}`}
                           >
                              <span className="slider round" />
                           </label>
                        </li>
                        <li className={`year ${toggleSwitch === false ? "active" : ""}`}>
                           <a href="#">Annually</a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div className="tabed-content">
                  <div id="month">
                     <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 wow animated fadeInLeft">
                           <div className="pricing-one__single">
                              <div className="pricig-heading">
                                 <h6>{flags?.pricing_plans_left_title_text.value}</h6>
                                 <p className="small-emphasis">Starting at</p>
                                 {toggleSwitch ? (
                                    <div className="price-range">
                                       <sup>$</sup>{" "}
                                       <span>
                                          {flags?.pricing_plans_left_monthlyprice_text.value}
                                       </span>
                                       <p>/user/month</p>
                                    </div>
                                 ) : (
                                    <div className="price-range">
                                       <sup>$</sup>{" "}
                                       <span>
                                          {flags?.pricing_plans_left_annualprice_text.value}
                                       </span>
                                       <p>/user/month</p>
                                    </div>
                                 )}
                                 {flags?.pricing_plans_left_intro_text.enabled && (
                                    <p>{flags?.pricing_plans_left_intro_text.value}</p>
                                 )}
                              </div>
                              <div className="pricig-body">
                                 <ul>
                                    {flags?.pricing_plans_left_detailintro_text.enabled && (
                                       <li className="detail-intro">
                                          {flags?.pricing_plans_left_detailintro_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_left_detail1_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_left_detail1_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_left_detail2_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_left_detail2_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_left_detail3_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_left_detail3_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_left_detail4_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_left_detail4_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_left_detail5_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_left_detail5_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_left_detail6_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_left_detail6_text.value}
                                       </li>
                                    )}
                                 </ul>
                                 <div className="pricing-btn mt-35">
                                    <a className="main-btn" href={WAITLIST_BUTTON_LINKS["starter"]}>
                                       {flags?.pricing_plans_left_button_text.value}
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow animated fadeInUp">
                           <div className="pricing-one__single center">
                              <div className="pricig-heading">
                                 <h6>{flags?.pricing_plans_middle_title_text.value}</h6>
                                 <p className="small-emphasis">Starting at</p>
                                 {toggleSwitch ? (
                                    <div className="price-range">
                                       <sup>$</sup>{" "}
                                       <span>
                                          {flags?.pricing_plans_middle_monthlyprice_text.value}
                                       </span>
                                       <p>/user/month</p>
                                    </div>
                                 ) : (
                                    <div className="price-range">
                                       <sup>$</sup>{" "}
                                       <span>
                                          {flags?.pricing_plans_middle_annualprice_text.value}
                                       </span>
                                       <p>/user/month</p>
                                    </div>
                                 )}
                                 {flags?.pricing_plans_middle_intro_text.enabled && (
                                    <p>{flags?.pricing_plans_middle_intro_text.value}</p>
                                 )}
                              </div>
                              <div className="pricig-body">
                                 <ul>
                                    {flags?.pricing_plans_middle_detailintro_text.enabled && (
                                       <li className="detail-intro">
                                          {flags?.pricing_plans_middle_detailintro_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_middle_detail1_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_middle_detail1_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_middle_detail2_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_middle_detail2_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_middle_detail3_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_middle_detail3_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_middle_detail4_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_middle_detail4_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_middle_detail5_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_middle_detail5_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_middle_detail6_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_middle_detail6_text.value}
                                       </li>
                                    )}
                                 </ul>
                                 <div className="pricing-btn mt-35">
                                    <a
                                       className="main-btn"
                                       href={
                                          toggleSwitch
                                             ? BUY_BUTTON_LINKS["professional_monthly"]
                                             : BUY_BUTTON_LINKS["professional_annual"]
                                       }
                                    >
                                       {flags?.pricing_plans_middle_button_text.value}
                                    </a>
                                 </div>
                              </div>
                              <div className="pricing-rebon">
                                 <span>{flags?.pricing_plans_popular_text.value}</span>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow animated fadeInRight">
                           <div className="pricing-one__single">
                              <div className="pricig-heading">
                                 <h6>{flags?.pricing_plans_right_title_text.value}</h6>
                                 {/*<p className="small-emphasis">Starting at</p>*/}
                                 {/* Make the below pt-24 and not pt-25 for pixel-perfect spacing*/}
                                 <p className="small-emphasis pt-25"> </p>
                                 {toggleSwitch ? (
                                    <div className="price-range">
                                       {/*<sup>$</sup>{" "}*/}
                                       <span>
                                          {flags?.pricing_plans_right_monthlyprice_text.value}
                                       </span>
                                       {/*<p>/user/month</p>*/}
                                    </div>
                                 ) : (
                                    <div className="price-range">
                                       {/*<sup>$</sup>{" "}*/}
                                       <span>
                                          {flags?.pricing_plans_right_annualprice_text.value}
                                       </span>
                                       {/*<p>/user/month</p>*/}
                                    </div>
                                 )}
                                 {flags?.pricing_plans_right_intro_text.enabled && (
                                    <p>{flags?.pricing_plans_right_intro_text.value}</p>
                                 )}
                              </div>
                              <div className="pricig-body">
                                 <ul>
                                    {flags?.pricing_plans_right_detailintro_text.enabled && (
                                       <li className="detail-intro">
                                          {flags?.pricing_plans_right_detailintro_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_right_detail1_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_right_detail1_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_right_detail2_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_right_detail2_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_right_detail3_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_right_detail3_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_right_detail4_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_right_detail4_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_right_detail5_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_right_detail5_text.value}
                                       </li>
                                    )}
                                    {flags?.pricing_plans_right_detail6_text.enabled && (
                                       <li>
                                          <i className="fal fa-check" />{" "}
                                          {flags?.pricing_plans_right_detail6_text.value}
                                       </li>
                                    )}
                                 </ul>
                                 <div className="pricing-btn mt-35">
                                    <PopupButton
                                       url={CALENDLY_LINKS["enterprise"]}
                                       rootElement={document.getElementById("root")}
                                       text={flags?.pricing_plans_right_button_text.value}
                                       className="main-btn"
                                    />
                                    {/*<a className="main-btn" href="#" onClick={() => {}}>*/}
                                    {/*   {flags?.pricing_plans_right_button_text.value}*/}
                                    {/*</a>*/}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
}

export default Plans;
