import flagsmith from "flagsmith";
import { useFlags, useFlagsmith } from "flagsmith/react";
import React from "react";
import thumb from "../../assets/images/traffic-thumb.png";
import homeProblem from "../../assets/images/home-problem2.jpg";
import { Link } from "react-router-dom";

function TrafficHome({ className }) {
   const flags = useFlags([
      "home_traffic_intro_text",
      "home_traffic_title_text",
      "home_traffic_content_text",
      "home_traffic_topleft_title_text",
      "home_traffic_topleft_content_text",
      "home_traffic_topright_title_text",
      "home_traffic_topright_content_text",
      "home_traffic_bottomleft_title_text",
      "home_traffic_bottomleft_content_text",
      "home_traffic_bottomright_title_text",
      "home_traffic_bottomright_content_text",
      "home_traffic_button_text",
      "home_traffic_button_link",
   ]);

   return (
      <section className={`appie-traffic-area pt-140 pb-180 ${className || ""}`}>
         <div className="container">
            <div className="row">
               <div className="col-lg-7">
                  <div className="appie-traffic-title">
                     {flags?.home_traffic_intro_text.enabled && (
                        <span>{flags?.home_traffic_intro_text.value}</span>
                     )}
                     <h3 className="title">{flags?.home_traffic_title_text.value}</h3>
                     {flags?.home_traffic_content_text.enabled && (
                        <p>{flags?.home_traffic_content_text.value}</p>
                     )}
                  </div>
                  <div className="row">
                     <div className="col-lg-6 col-md-6">
                        <div className="appie-traffic-service mb-30">
                           <div className="icon">
                              <i className="fal fa-check" />
                           </div>
                           <h5 className="title">{flags?.home_traffic_topleft_title_text.value}</h5>
                           {flags?.home_traffic_topleft_content_text.enabled && (
                              <p>{flags?.home_traffic_topleft_content_text.value}</p>
                           )}
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-6">
                        <div className="appie-traffic-service item-2 mb-30">
                           <div className="icon">
                              <i className="fal fa-check" />
                           </div>
                           <h5 className="title">
                              {flags?.home_traffic_topright_title_text.value}
                           </h5>
                           {flags?.home_traffic_topright_content_text.enabled && (
                              <p>{flags?.home_traffic_topright_content_text.value}</p>
                           )}
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-6">
                        <div className="appie-traffic-service item-3">
                           <div className="icon">
                              <i className="fal fa-check" />
                           </div>
                           <h5 className="title">
                              {flags?.home_traffic_bottomleft_title_text.value}
                           </h5>
                           {flags?.home_traffic_bottomleft_content_text.enabled && (
                              <p>{flags?.home_traffic_bottomleft_content_text.value}</p>
                           )}
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-6">
                        <div className="appie-traffic-service item-4">
                           <div className="icon">
                              <i className="fal fa-check" />
                           </div>
                           <h5 className="title">
                              {flags?.home_traffic_bottomright_title_text.value}
                           </h5>
                           {flags?.home_traffic_bottomright_content_text.enabled && (
                              <p>{flags?.home_traffic_bottomright_content_text.value}</p>
                           )}
                        </div>
                     </div>
                     <div className="col-lg-12">
                        {flags?.home_traffic_button_text.enabled && (
                           <div className="traffic-btn mt-50">
                              <Link className="main-btn" to="/pricing">
                                 {flags?.home_traffic_button_text.value}{" "}
                                 <i className="fal fa-arrow-right" />
                              </Link>
                           </div>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="traffic-thumb ">
            <img
               className="wow animated fadeInRight"
               data-wow-duration="2000ms"
               data-wow-delay="200ms"
               src={homeProblem}
               alt=""
               width="846px"
            />
         </div>
      </section>
   );
}

export default TrafficHome;
