import flagsmith from "flagsmith";
import { useFlags, useFlagsmith } from "flagsmith/react";
import React from "react";
import featureThumb from "../../assets/images/features-thumb-2.png";
import homeFeatures from "../../assets/images/home-features2.jpg";
import shape13 from "../../assets/images/shape/shape-13.png";
import shape14 from "../../assets/images/shape/shape-14.png";
import shape15 from "../../assets/images/shape/shape-15.png";

function HighlightsHome() {
   const flags = useFlags([
      "home_highlights_title_text",
      "home_highlights_content_text",
      "home_highlights_tab1",
      "home_highlights_tab1_title_text",
      "home_highlights_tab1_content_text",
      "home_highlights_tab2",
      "home_highlights_tab2_title_text",
      "home_highlights_tab2_content_text",
      "home_highlights_tab3",
      "home_highlights_tab3_title_text",
      "home_highlights_tab3_content_text",
      "home_highlights_tab4",
      "home_highlights_tab4_title_text",
      "home_highlights_tab4_content_text",
      "home_highlights_hover_effects",
      "home_highlights_floating_shapes",
   ]);
   return (
      <>
         <section className="appie-features-area-2 pt-140 pb-100" id="features">
            <div className="container">
               <div className="row justify-content-center">
                  <div className="col-lg-12">
                     <div className="appie-section-title appie-section-title-2 text-center">
                        <h3 className="appie-title">{flags?.home_highlights_title_text.value}</h3>
                        <p>{flags?.home_highlights_content_text.value}</p>
                     </div>
                  </div>
               </div>
               <div className="row mt-30 align-items-center">
                  <div className="col-lg-6">
                     <div className="appie-features-boxes">
                        {flags?.home_highlights_tab1.enabled && (
                           <div
                              className={`appie-features-box-item ${
                                 flags?.home_highlights_hover_effects.enabled
                                    ? ""
                                    : "disable-hover-effect"
                              }`}
                           >
                              <h4 className="title">
                                 {flags?.home_highlights_tab1_title_text.value}
                              </h4>
                              {flags?.home_highlights_tab1_content_text.enabled && (
                                 <p>{flags?.home_highlights_tab1_content_text.value}</p>
                              )}
                           </div>
                        )}
                        {flags?.home_highlights_tab2.enabled && (
                           <div
                              className={`appie-features-box-item item-2 ${
                                 flags?.home_highlights_hover_effects.enabled
                                    ? ""
                                    : "disable-hover-effect"
                              }`}
                           >
                              <h4 className="title">
                                 {flags?.home_highlights_tab2_title_text.value}
                              </h4>
                              {flags?.home_highlights_tab2_content_text.enabled && (
                                 <p>{flags?.home_highlights_tab2_content_text.value}</p>
                              )}
                           </div>
                        )}
                        {flags?.home_highlights_tab3.enabled && (
                           <div
                              className={`appie-features-box-item item-3 ${
                                 flags?.home_highlights_hover_effects.enabled
                                    ? ""
                                    : "disable-hover-effect"
                              }`}
                           >
                              <h4 className="title">
                                 {flags?.home_highlights_tab3_title_text.value}
                              </h4>
                              {flags?.home_highlights_tab3_content_text.enabled && (
                                 <p>{flags?.home_highlights_tab3_content_text.value}</p>
                              )}
                           </div>
                        )}
                        {flags?.home_highlights_tab4.enabled && (
                           <div
                              className={`appie-features-box-item item-4 ${
                                 flags?.home_highlights_hover_effects.enabled
                                    ? ""
                                    : "disable-hover-effect"
                              }`}
                           >
                              <h4 className="title">
                                 {flags?.home_highlights_tab4_title_text.value}
                              </h4>
                              {flags?.home_highlights_tab4_content_text.enabled && (
                                 <p>{flags?.home_highlights_tab4_content_text.value}</p>
                              )}
                           </div>
                        )}
                     </div>
                  </div>
                  <div className="col-lg-6">
                     <div
                        className="appie-features-thumb wow animated fadeInRight"
                        data-wow-duration="2000ms"
                        data-wow-delay="200ms"
                     >
                        <img src={homeFeatures} alt="" />
                     </div>
                  </div>
               </div>
            </div>
            {flags?.home_highlights_floating_shapes.enabled && (
               <>
                  <div className="features-shape-1">
                     <img src={shape15} alt="" />
                  </div>
                  <div className="features-shape-2">
                     <img src={shape14} alt="" />
                  </div>
                  <div className="features-shape-3">
                     <img src={shape13} alt="" />
                  </div>
               </>
            )}
         </section>
      </>
   );
}

export default HighlightsHome;
