import React from "react";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import Drawer from "../Mobile/Drawer";
import AboutHomeEight from "./AboutHomeEight";
import FooterWelcome from "./FooterWelcome";
import HeaderWelcome from "./HeaderWelcome";
import HeroWelcome from "./HeroWelcome";
import NowWhatWelcome from "./NowWhatWelcome";

function HomeEight() {
   const [drawer, setDrawer] = useToggle(false);
   return (
      <>
         <Drawer drawer={drawer} action={setDrawer.toggle} />
         <HeaderWelcome drawer={drawer} action={setDrawer.toggle} />
         <HeroWelcome />
         {/*<AboutHomeEight />*/}
         <NowWhatWelcome />
         <FooterWelcome />
         <BackToTop className="back-to-top-8" />
      </>
   );
}

export default HomeEight;
