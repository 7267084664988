import React from "react";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import FeaturesHomeOne from "../HomeOne/FeaturesHomeOne";
import Plans from "./Plans";
import BlogHomeThree from "../HomeThree/BlogHomeThree";
import DownloadHomeThree from "../HomeThree/DownloadHomeThree";
import CounterArea from "../HomeTwo/CounterArea";
import ServicesHomeTwo from "../HomeTwo/ServicesHomeTwo";
import VideoPlayerHomeTwo from "../HomeTwo/VideoPlayerHomeTwo";
import Drawer from "../Mobile/Drawer";
import FooterPricing from "./FooterPricing";
import HeaderPricing from "./HeaderPricing";
import HeroPricing from "./HeroPricing";
import FooterHome from "../Home/FooterHome";
import FAQHome from "../Home/FAQHome";

function Pricing() {
   const [drawer, drawerAction] = useToggle(false);
   return (
      <>
         <Drawer drawer={drawer} action={drawerAction.toggle} />
         <HeaderPricing action={drawerAction.toggle} />
         {/*<HeroPricing />*/}
         {/*<ServicesHomeTwo className="pt-90" />*/}
         <BackToTop className="back-to-top-6" />
         <Plans className="appie-pricing-6-area" />
         <FAQHome />
         {/*<BlogHomeThree />*/}
         <FooterHome />
      </>
   );
}

export default Pricing;
