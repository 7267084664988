import flagsmith from "flagsmith";
import { useFlags, useFlagsmith } from "flagsmith/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function FaqHomeOne({ className }) {
   const [showQues, setQues] = useState(1);
   const [tabIndices, setTabIndices] = useState({});

   // FAQ tabs can be enabled and disabled, so to allow the showQues system to function with disabled tabs in the
   // middle, we need to make a custom mapping from the tab flags to the tab indices, done in the useEffect below.
   const num_possible_tabs = 10;

   const flags = useFlags([
      "pricing_faq_title_text",
      "pricing_faq_content_text",
      "pricing_faq_tab1",
      "pricing_faq_tab1_title_text",
      "pricing_faq_tab1_content_text",
      "pricing_faq_tab2",
      "pricing_faq_tab2_title_text",
      "pricing_faq_tab2_content_text",
      "pricing_faq_tab3",
      "pricing_faq_tab3_title_text",
      "pricing_faq_tab3_content_text",
      "pricing_faq_tab4",
      "pricing_faq_tab4_title_text",
      "pricing_faq_tab4_content_text",
      "pricing_faq_tab5",
      "pricing_faq_tab5_title_text",
      "pricing_faq_tab5_content_text",
      "pricing_faq_tab6",
      "pricing_faq_tab6_title_text",
      "pricing_faq_tab6_content_text",
      "pricing_faq_intro_text",
   ]);

   // Map the tab flags to the tab indices, accounting for disabled tabs.
   // TODO: K exists because j is updating before the state is set. Fix that hack later
   useEffect(() => {
      let j = 1;
      for (let i = 1; i <= num_possible_tabs; i++) {
         if (flags && flags[`pricing_faq_tab${i}`]?.enabled) {
            const k = j + 0;
            setTabIndices((prev) => ({
               ...prev,
               [`pricing_faq_tab${i}`]: k,
            }));
            j++;
         }
      }
   }, [flags.pricing_faq_tab1.enabled]);

   const openQuestion = (value) => {
      setQues(value);
   };

   return (
      <>
         <section className={`appie-faq-area pb-95 ${className || ""}`}>
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="appie-section-title text-center">
                        <h3 className="appie-title">{flags?.pricing_faq_title_text.value}</h3>
                        {flags?.pricing_faq_content_text.enabled && (
                           <p>{flags?.pricing_faq_content_text.value}</p>
                        )}
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-12">
                     <div
                        className="faq-accordion wow fadeInRight mt-30"
                        data-wow-duration="1500ms"
                     >
                        <div
                           className="accrodion-grp animated fadeIn faq-accrodion wow"
                           data-wow-duration="1500ms"
                           data-grp-name="faq-accrodion"
                        >
                           {Object.entries(tabIndices).map(([key, index]) => (
                              <div
                                 onClick={() => openQuestion(index)}
                                 className={`accrodion ${showQues === index ? "active" : ""}`}
                                 key={key}
                              >
                                 <div className="accrodion-inner">
                                    <div className="accrodion-title">
                                       <h4>{flags && flags[`${key}_title_text`].value}</h4>
                                    </div>
                                    <div
                                       className="accrodion-content"
                                       style={{
                                          display: showQues === index ? "block" : "none",
                                       }}
                                    >
                                       <div className="inner">
                                          <p>{flags && flags[`${key}_content_text`].value}</p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-12">
                     {flags?.pricing_faq_intro_text.enabled ? (
                        <div className="faq-text text-center pt-40">
                           <p>
                              {flags?.pricing_faq_intro_text.value}{" "}
                              <Link to="/contact">Contact Us</Link>
                           </p>
                        </div>
                     ) : (
                        // This should really be pt-64 to make it pixel-perfect identical to enabled above
                        <div className="faq-text text-center pt-65"></div>
                     )}
                  </div>
               </div>
            </div>
         </section>
      </>
   );
}

export default FaqHomeOne;
