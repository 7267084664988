import flagsmith from "flagsmith";
import { useFlags, useFlagsmith } from "flagsmith/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import thumb from "../../assets/images/features-thumb-1.png";
import shapeSix from "../../assets/images/shape/shape-6.png";
import shapeSeven from "../../assets/images/shape/shape-7.png";
import shapeEight from "../../assets/images/shape/shape-8.png";

function FeaturesHome({ className }) {
   const [tab, setTab] = useState("setting");

   const flags = useFlags([
      "home_features_floating_shapes",
      "home_features_tab1",
      "home_features_tab1_tab_text",
      "home_features_tab1_intro_text",
      "home_features_tab1_title_text",
      "home_features_tab1_content_text",
      "home_features_tab1_button_text",
      "home_features_tab1_button_link",
      "home_features_tab2",
      "home_features_tab2_tab_text",
      "home_features_tab2_intro_text",
      "home_features_tab2_title_text",
      "home_features_tab2_content_text",
      "home_features_tab2_button_text",
      "home_features_tab2_button_link",
      "home_features_tab3",
      "home_features_tab3_tab_text",
      "home_features_tab3_intro_text",
      "home_features_tab3_title_text",
      "home_features_tab3_content_text",
      "home_features_tab3_button_text",
      "home_features_tab3_button_link",
      "home_features_tab4",
      "home_features_tab4_tab_text",
      "home_features_tab4_intro_text",
      "home_features_tab4_title_text",
      "home_features_tab4_content_text",
      "home_features_tab4_button_text",
      "home_features_tab4_button_link",
   ]);

   const handleClick = (e, value) => {
      e.preventDefault();
      setTab(value);
   };

   return (
      <section className={`appie-features-area pt-100 ${className}`} id="features">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-lg-3">
                  <div className="appie-features-tabs-btn">
                     <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                     >
                        {flags?.home_features_tab1.enabled && (
                           <a
                              onClick={(e) => handleClick(e, "setting")}
                              className={`nav-link ${tab === "setting" ? "active" : ""}`}
                              id="v-pills-home-tab"
                              data-toggle="pill"
                              href="#v-pills-home"
                              role="tab"
                              aria-controls="v-pills-home"
                              aria-selected="true"
                           >
                              <i className="fas fa-cog" />
                              {flags?.home_features_tab1_tab_text.value}
                           </a>
                        )}
                        {flags?.home_features_tab2.enabled && (
                           <a
                              onClick={(e) => handleClick(e, "report")}
                              className={`nav-link ${tab === "report" ? "active" : ""}`}
                              id="v-pills-profile-tab"
                              data-toggle="pill"
                              href="#v-pills-profile"
                              role="tab"
                              aria-controls="v-pills-profile"
                              aria-selected="false"
                           >
                              <i className="fas fa-exclamation-triangle" />
                              {flags?.home_features_tab2_tab_text.value}
                           </a>
                        )}
                        {flags?.home_features_tab3.enabled && (
                           <a
                              onClick={(e) => handleClick(e, "notice")}
                              className={`nav-link ${tab === "notice" ? "active" : ""}`}
                              id="v-pills-messages-tab"
                              data-toggle="pill"
                              href="#v-pills-messages"
                              role="tab"
                              aria-controls="v-pills-messages"
                              aria-selected="false"
                           >
                              <i className="fas fa-bell" />
                              {flags?.home_features_tab3_tab_text.value}
                           </a>
                        )}
                        {flags?.home_features_tab4.enabled && (
                           <a
                              onClick={(e) => handleClick(e, "app")}
                              className={`nav-link ${tab === "app" ? "active" : ""}`}
                              id="v-pills-settings-tab"
                              data-toggle="pill"
                              href="#v-pills-settings"
                              role="tab"
                              aria-controls="v-pills-settings"
                              aria-selected="false"
                           >
                              <i className="fas fa-lock" />
                              {flags?.home_features_tab4_tab_text.value}
                           </a>
                        )}
                     </div>
                  </div>
               </div>
               <div className="col-lg-9">
                  <div className="tab-content" id="v-pills-tabContent">
                     {flags?.home_features_tab1.enabled && (
                        <div
                           className={`${tab === "setting" ? "show active" : ""} tab-pane fade`}
                           id="v-pills-home"
                           role="tabpanel"
                           aria-labelledby="v-pills-home-tab"
                        >
                           <div className="row align-items-center">
                              <div className="col-lg-6">
                                 <div
                                    className="appie-features-thumb text-center wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                 >
                                    <img src={thumb} alt="" />
                                 </div>
                              </div>
                              <div className="col-lg-6">
                                 <div
                                    className="appie-features-content wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                 >
                                    {flags?.home_features_tab1_intro_text.enabled && (
                                       <span>{flags?.home_features_tab1_intro_text.value}</span>
                                    )}

                                    <h3 className="title">
                                       {flags?.home_features_tab1_title_text.value}
                                    </h3>
                                    <p>{flags?.home_features_tab1_content_text.value}</p>
                                    {flags?.home_features_tab1_button_text.enabled && (
                                       <a
                                          className="main-btn"
                                          href={flags?.home_features_tab1_button_link.value}
                                       >
                                          {flags?.home_features_tab1_button_text.value}
                                       </a>
                                    )}
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}
                     {flags?.home_features_tab2.enabled && (
                        <div
                           className={`${tab === "report" ? "show active" : ""} tab-pane fade`}
                           id="v-pills-profile"
                           role="tabpanel"
                           aria-labelledby="v-pills-profile-tab"
                        >
                           <div className="row align-items-center">
                              <div className="col-lg-6">
                                 <div
                                    className="appie-features-thumb text-center animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                 >
                                    <img src={thumb} alt="" />
                                 </div>
                              </div>
                              <div className="col-lg-6">
                                 <div
                                    className="appie-features-content wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                 >
                                    {flags?.home_features_tab2_intro_text.enabled && (
                                       <span>{flags?.home_features_tab2_intro_text.value}</span>
                                    )}

                                    <h3 className="title">
                                       {flags?.home_features_tab2_title_text.value}
                                    </h3>
                                    <p>{flags?.home_features_tab2_content_text.value}</p>
                                    {flags?.home_features_tab2_button_text.enabled && (
                                       <a
                                          className="main-btn"
                                          href={flags?.home_features_tab2_button_link.value}
                                       >
                                          {flags?.home_features_tab2_button_text.value}
                                       </a>
                                    )}
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}
                     {flags?.home_features_tab3.enabled && (
                        <div
                           className={`${tab === "notice" ? "show active" : ""} tab-pane fade`}
                           id="v-pills-messages"
                           role="tabpanel"
                           aria-labelledby="v-pills-messages-tab"
                        >
                           <div className="row align-items-center">
                              <div className="col-lg-6">
                                 <div
                                    className="appie-features-thumb text-center animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                 >
                                    <img src={thumb} alt="" />
                                 </div>
                              </div>
                              <div className="col-lg-6">
                                 <div
                                    className="appie-features-content wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                 >
                                    {flags?.home_features_tab3_intro_text.enabled && (
                                       <span>{flags?.home_features_tab3_intro_text.value}</span>
                                    )}

                                    <h3 className="title">
                                       {flags?.home_features_tab3_title_text.value}
                                    </h3>
                                    <p>{flags?.home_features_tab3_content_text.value}</p>
                                    {flags?.home_features_tab3_button_text.enabled && (
                                       <a
                                          className="main-btn"
                                          href={flags?.home_features_tab3_button_link.value}
                                       >
                                          {flags?.home_features_tab3_button_text.value}
                                       </a>
                                    )}
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}
                     {flags?.home_features_tab4.enabled && (
                        <div
                           className={`${tab === "app" ? "show active" : ""} tab-pane fade`}
                           id="v-pills-settings"
                           role="tabpanel"
                           aria-labelledby="v-pills-settings-tab"
                        >
                           <div className="row align-items-center">
                              <div className="col-lg-6">
                                 <div
                                    className="appie-features-thumb text-center animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                 >
                                    <img src={thumb} alt="" />
                                 </div>
                              </div>
                              <div className="col-lg-6">
                                 <div
                                    className="appie-features-content wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                 >
                                    {flags?.home_features_tab4_intro_text.enabled && (
                                       <span>{flags?.home_features_tab4_intro_text.value}</span>
                                    )}

                                    <h3 className="title">
                                       {flags?.home_features_tab4_title_text.value}
                                    </h3>
                                    <p>{flags?.home_features_tab4_content_text.value}</p>
                                    {flags?.home_features_tab4_button_text.enabled && (
                                       <a
                                          className="main-btn"
                                          href={flags?.home_features_tab4_button_link.value}
                                       >
                                          {flags?.home_features_tab4_button_text.value}
                                       </a>
                                    )}
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </div>
         {flags?.home_features_floating_shapes.enabled && (
            <>
               <div className="features-shape-1">
                  <img src={shapeSix} alt="" />
               </div>
               <div className="features-shape-2">
                  <img src={shapeSeven} alt="" />
               </div>
               <div className="features-shape-3">
                  <img src={shapeEight} alt="" />
               </div>
            </>
         )}
      </section>
   );
}

export default FeaturesHome;
