import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Routes from "./Routes";
import { FpjsProvider, FingerprintJSPro } from "@fingerprintjs/fingerprintjs-pro-react";

function App() {
   return (
      <FpjsProvider
         loadOptions={{
            apiKey: "nScBF9AUaz0kHfkuYAWd",
            endpoint: ["https://metrics.remymoney.com", FingerprintJSPro.defaultEndpoint],
            scriptUrlPattern: [
               "https://metrics.remymoney.com/web/v3/nScBF9AUaz0kHfkuYAWd/loader_v3.8.6.js",
               FingerprintJSPro.defaultScriptUrlPattern,
            ],
         }}
      >
         <FlagsmithProvider
            options={{
               environmentID: "m4KFajtVKpGC23R5SnEmYr",
            }}
            flagsmith={flagsmith}
         >
            <Routes />
         </FlagsmithProvider>
      </FpjsProvider>
   );
}

export default App;
