import React from "react";
import { Link } from "react-router-dom";

function Navigation({ lang = false }) {
   // Note: Use `lang` check to enable right-to-left support if desired
   return (
      <ul>
         <li>
            {/*<a href="#">*/}
            <Link to="/">Home</Link>
            {/*<i className="fal fa-angle-down" />*/}
            {/*</a>*/}
            {/*<ul className="sub-menu">*/}
            {/*   <li>*/}
            {/*      <Link to="/">Home 1</Link>*/}
            {/*   </li>*/}
            {/*   <li>*/}
            {/*      <Link to="/home-two">Home 2</Link>*/}
            {/*   </li>*/}
            {/*   <li>*/}
            {/*      <Link to="/home-three">Home 3</Link>*/}
            {/*   </li>*/}
            {/*   <li>*/}
            {/*      <Link to="/home-four">Home 4</Link>*/}
            {/*   </li>*/}
            {/*   <li>*/}
            {/*      <Link to="/home-five">Home 5</Link>*/}
            {/*   </li>*/}
            {/*   <li>*/}
            {/*      <Link to="/home-six">Home 6</Link>*/}
            {/*   </li>*/}
            {/*   <li>*/}
            {/*      <Link to="/home-seven">Home 7</Link>*/}
            {/*   </li>*/}
            {/*   <li>*/}
            {/*      <Link to="/home-eight">Home 8</Link>*/}
            {/*   </li>*/}
            {/*   <li>*/}
            {/*      <Link to="/home-dark">Home Dark</Link>*/}
            {/*   </li>*/}
            {/*   <li>*/}
            {/*      <Link to="/home-rtl">Home Rtl</Link>*/}
            {/*   </li>*/}
            {/*</ul>*/}
         </li>
         <li>
            <Link to="/pricing">Pricing</Link>
         </li>
         <li>
            <Link to="/contact">Contact</Link>
         </li>
      </ul>
   );
}

export default Navigation;
