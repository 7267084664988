import React from "react";
import hero from "../../assets/images/hero-thumb-9.png";
import shapeOne from "../../assets/images/shape/1.png";
import shapeTwo from "../../assets/images/shape/2.png";
import shapeThree from "../../assets/images/shape/3.png";
import shapeFour from "../../assets/images/shape/4.png";
import { PopupButton } from "react-calendly";

const CALENDLY_URL = "https://calendly.com/jay-jacobs/onboarding";

function HeroWelcome() {
   return (
      <>
         <section className="appie-hero-area appie-hero-8-area">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-6">
                     <div className="appie-hero-content appie-hero-content-8">
                        <h1 className="appie-title">Welcome to Remy</h1>
                        <p>
                           Now let's get you set up. Schedule time with a member of our team to
                           start seeing what Remy can do for you.
                        </p>
                        <ul>
                           <li>
                              <PopupButton
                                 url={CALENDLY_URL}
                                 rootElement={document.getElementById("root")}
                                 text="Book Onboarding"
                              />
                           </li>
                           {/*<li>*/}
                           {/*   <a className="item-2" href="#">*/}
                           {/*      <i className="fab fa-apple" /> <span>App Store</span>*/}
                           {/*   </a>*/}
                           {/*</li>*/}
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-6">
                     <div className="appie-hero-thumb-6">
                        <div
                           className="thumb text-center wow animated fadeInUp"
                           data-wow-duration="1000ms"
                           data-wow-delay="600ms"
                        >
                           <img src={hero} alt="" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="home-8-shape-1">
               <img src={shapeThree} alt="" />
            </div>
            <div className="home-8-shape-2">
               <img src={shapeFour} alt="" />
            </div>
            <div className="home-8-shape-3">
               <img src={shapeOne} alt="" />
            </div>
            <div className="home-8-shape-4">
               <img src={shapeTwo} alt="" />
            </div>
         </section>
      </>
   );
}

export default HeroWelcome;
